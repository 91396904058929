import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "palette"
    }}>{`Palette`}</h1>
    <p>{`Progress Mfg. uses three distinct colors across all brands online. Palettes should consist of shades just as outlined in `}<a parentName="p" {...{
        "href": "https://material.io/design/color/the-color-system.html#color-usage-palettes"
      }}>{`Material Design guidelines`}</a>{`. Colors can also be seen and tested on the `}<a parentName="p" {...{
        "href": "https://material.io/resources/color/#!/?view.left=0&view.right=1"
      }}>{`Material Design Color Tool`}</a>{`.
Any time a color is used on top of the secondary red color, the contrast needs to meet accessibility guidelines, with a ratio greater than 7:1. If you are unsure if a particular shade meets those guidelines they can be tested here: `}<a parentName="p" {...{
        "href": "https://webaim.org/resources/contrastchecker/"
      }}>{`WebAIM.org`}</a>{`.`}</p>
    <h3 {...{
      "id": "primary-color"
    }}>{`Primary Color`}</h3>
    <Box style={{
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    }} mdxType="Box">
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#1B1816"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#1B1816</Typography>
        <Typography variant="subtitle2" mdxType="Typography">900</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#1F1C1A"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#1F1C1A</Typography>
        <Typography variant="subtitle2" mdxType="Typography">800</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#24201E"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#24201E</Typography>
        <Typography variant="subtitle2" mdxType="Typography">700</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#282422"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#282422</Typography>
        <Typography variant="subtitle2" mdxType="Typography">600</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#2d2926"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#2d2926</Typography>
        <Typography variant="subtitle2" mdxType="Typography">Primary (500)</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#423E3B"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#423E3B</Typography>
        <Typography variant="subtitle2" mdxType="Typography">400</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#575351"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#575351</Typography>
        <Typography variant="subtitle2" mdxType="Typography">300</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#6C6967"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#6C6967</Typography>
        <Typography variant="subtitle2" mdxType="Typography">200</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#817E7C"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#817E7C</Typography>
        <Typography variant="subtitle2" mdxType="Typography">100</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#969492"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#969492</Typography>
        <Typography variant="subtitle2" mdxType="Typography">50</Typography>
    </Box>
    </Box>
    <h3 {...{
      "id": "secondary-color"
    }}>{`Secondary Color`}</h3>
    <Box style={{
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    }} mdxType="Box">
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#44000C"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#44000C</Typography>
        <Typography variant="subtitle2" mdxType="Typography">900</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#5B0010"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#5B0010</Typography>
        <Typography variant="subtitle2" mdxType="Typography">800</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#720015"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#720015</Typography>
        <Typography variant="subtitle2" mdxType="Typography">700</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#880019"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#880019</Typography>
        <Typography variant="subtitle2" mdxType="Typography">600</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#9F001D"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#9F001D</Typography>
        <Typography variant="subtitle2" mdxType="Typography">500</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#B60021"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#B60021</Typography>
        <Typography variant="subtitle2" mdxType="Typography">400</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#CD0025"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#CD0025</Typography>
        <Typography variant="subtitle2" mdxType="Typography">300</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#E4002A"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#E4002A</Typography>
        <Typography variant="subtitle2" mdxType="Typography">Secondary (200)</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#E6193F"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#E6193F</Typography>
        <Typography variant="subtitle2" mdxType="Typography">100</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#E93354"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#E93354</Typography>
        <Typography variant="subtitle2" mdxType="Typography">50</Typography>
    </Box>
    </Box>
    <h3 {...{
      "id": "true-black"
    }}>{`True Black`}</h3>
    <Box style={{
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    }} mdxType="Box">
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#000000"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#000000</Typography>
    </Box>
    <Box style={{
        marginRight: "15px"
      }} mdxType="Box">
        <blockquote style={{
          borderLeft: "2px solid yellow"
        }}>True black should be used sparingly and with an opacity of 85% on text so it's not harsh to the reader.</blockquote>
    </Box>
    </Box>
    <h3 {...{
      "id": "true-white"
    }}>{`True White`}</h3>
    <Box style={{
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    }} mdxType="Box">
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#FFFFFF"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#FFFFFF</Typography>
    </Box>
    <Box style={{
        marginRight: "15px"
      }} mdxType="Box">
        <blockquote style={{
          borderLeft: "2px solid yellow"
        }}>True white should be used for the majority of backgrounds, especially if there is heavy text.</blockquote>
    </Box>
    </Box>
    <h3 {...{
      "id": "defunt-colors"
    }}>{`Defunt Colors`}</h3>
    <Box style={{
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap"
    }} mdxType="Box">
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#653024"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#653024</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#d3bc8d"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#D3BC8D</Typography>
    </Box>
    <Box style={{
        marginRight: "15px",
        marginBottom: "15px",
        textAlign: "center"
      }} mdxType="Box">
        <Paper style={{
          height: "100px",
          width: "100px",
          borderRadius: "100%",
          backgroundColor: "#a2aaad"
        }} mdxType="Paper" />
        <Typography variant="subtitle2" mdxType="Typography">#A2AAAD</Typography>
    </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      